import { Api } from "api";

export const getMiniAppNotifications = async (searchParams: URLSearchParams, page?: number) => {
  let url = "/notifications/v1/admin/mini_app_notifications";
  let params = searchParams ? `?${searchParams}` : "";
  let accessToken = localStorage.getItem("accessToken");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};

export const getNotificationsEnabledByUsers = async (searchParams: URLSearchParams, page?: number) => {
  let url = "/notifications/v1/admin/users_notifications";
  let params = searchParams ? `?${searchParams}` : "";
  let accessToken = localStorage.getItem("accessToken");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};
