import { useState, useEffect } from "react";
import { useSharedState } from "sharedStateProvider";
import { EnabledResponseType } from "types/notification-type";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine } from "components/tables/table-parts";
import { getNotificationsEnabledByUsers } from "api/notifications";
import { FiltersList } from "../../types/page-layout-types";
import { getMiniApps } from "../../api/mini-apps";
import { MiniAppsResponseType } from "../../types/mini-app-types";

export default function EnabledNotifications() {
  const { notify } = useSharedState();

  const [notifications, setNotifications] = useState<EnabledResponseType>(emptyPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [miniAppsFilterList, setMiniAppsFilterList] = useState<FiltersList>();
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());
  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const fetchMiniApps = async (page?: number) => {
    getMiniApps(searchParams, page).then((res: MiniAppsResponseType) => {
      formattedMiniAppsList(res);
    });
  };

  const formattedMiniAppsList = (res: MiniAppsResponseType) => {
    const filterListMiniApps: FiltersList = {
      type: [
        {
          name: "Приложение",
          value: "",
          filter_name: "search",
          disabled: true,
        },
      ],
    };

    res.data.forEach((app) => {
      filterListMiniApps.type.push({
        name: app.names.en,
        value: app.id,
        filter_name: "search",
        disabled: false,
      });
    });

    setMiniAppsFilterList(filterListMiniApps);
  };

  const appendParam = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearFilters = async (param?: string) => {
    if (param) {
      param && searchParams.delete(param);
    } else {
      searchParams.delete("search");
      searchParams.delete("from");
      searchParams.delete("to");
    }
  };

  const getAndSetNotifications = async (page?: number) => {
    setIsLoading(true);
    getNotificationsEnabledByUsers(searchParams, page)
      .then((res: EnabledResponseType) => setNotifications(res))
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при загрузке уведомлений");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    Promise.all([getAndSetNotifications(), fetchMiniApps()]);
  }, []);

  const notificationsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: false },
    { header_text: "ID", narrow: true, wrap: false },
    { header_text: "Мини-приложение", narrow: false, wrap: false },
    { header_text: "Пользователь", narrow: false, wrap: false },
    { header_text: "Изменено", narrow: false, wrap: false },
    { header_text: "Создано", narrow: false, wrap: false },
  ];

  const notificationsList = (
    <>
      {notifications.data.map((enabled, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(enabled.id)}</td>
          <td>{oneLine(enabled.mini_app_name)}</td>
          <td>{oneLine(enabled.user_full_name)}</td>
          <td>{oneLine(new Date(enabled.updated_at).toLocaleString())}</td>
          <td>{oneLine(new Date(enabled.created_at).toLocaleString())}</td>
        </tr>
      ))}
    </>
  );

  const notificationsTable = Table(
    notificationsTableHeaders,
    notificationsList,
    true,
    notifications,
    updateFirstEntry,
    getAndSetNotifications
  );

  const notificationsHeader = "Разрешённые уведомления";
  const notificationsSubheaderText = "Количество разрешённых уведомлений: ";
  const notificationsSubheaderCount = notifications.total;

  return (
    <>
      <PageLayout
        children={notificationsTable}
        isLoading={isLoading}
        filters={true}
        filtersList={miniAppsFilterList}
        appendFilter={appendParam}
        clearFilters={clearFilters}
        search={true}
        searchAPI={getAndSetNotifications}
        header={notificationsHeader}
        subheaderText={notificationsSubheaderText}
        subheaderCount={notificationsSubheaderCount}
        dataLength={notifications.data.length}
        dateRange={false}
      />
    </>
  );
}
