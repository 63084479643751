import { Api } from "api";

export const authorize: any = async (payload: {
  phone: string;
  password: string;
}) => {
  const res = await Api.post("/admin/auth/v1/login", payload);
  localStorage.setItem("accessToken", res.data.access.hash);
  localStorage.setItem("refreshToken", res.data.refresh.hash);
  return res.data;
};

export const refreshToken: any = async () => {
  const refresh = localStorage.getItem("refreshToken");
  const res = await Api.post("/auth/v1/refresh", { token: refresh });
  return res.data;
};
