import { useEffect, useState } from "react";
import { emptyPagination } from "types/pagination-types";
import { MiniAppsResponseType, MiniAppUpdateResponseType } from "types/mini-app-types";
import { useSharedState } from "sharedStateProvider";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { getMiniApps, getUpdates, reviewRequest, reviewUpdate } from "api/mini-apps";
import { avatarWithTwoLines, checkOrXIcon, mutedLine, oneLine } from "components/tables/table-parts";
import DangerModal from "components/modals/danger";
import RefuseReasonModal from "components/modals/refuse-reason";
import ActionButton from "components/tables/action-btn";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

type moderationTypes = "approveRequest" | "refuseRequest" | "approveUpdate" | "refuseUpdate" | "";

export default function Moderation() {
  const { notify } = useSharedState();

  const [miniAppId, setMiniAppId] = useState<string>("");
  const [requests, setRequests] = useState<MiniAppsResponseType>(emptyPagination);
  const [updates, setUpdates] = useState<MiniAppUpdateResponseType>(emptyPagination);
  const [requestsFirstEntry, setRequestsFirstEntry] = useState<number>(0);
  const [updatesFirstEntry, setUpdatesFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());
  searchParams.set("review_mode", "pending");

  const dangerDescription = "Вы действительно хотите одобрить обновление?";
  const [moderationAction, setModerationAction] = useState<moderationTypes>("");
  const [refuseReason, setRefuseReason] = useState<string>("");

  const updateRequestFirstEntry = (value: number) => {
    setRequestsFirstEntry(value);
  };

  const updateFirstEntry = (value: number) => {
    setUpdatesFirstEntry(value);
  };

  const getAndSetRequests = async () => {
    setIsLoading(true);
    getMiniApps(searchParams)
      .then((res: MiniAppsResponseType) => {
        setRequests(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении списка модерации");
      })
      .finally(() => setIsLoading(false));
  };

  const getAndSetUpdates = async () => {
    setIsLoading(true);
    getUpdates()
      .then((res: any) => {
        setUpdates(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении списка модерации");
      })
      .finally(() => setIsLoading(false));
  };

  const onRequest = async (id: string, status: string) => {
    setIsLoading(true);
    reviewRequest(id, { status: status, comment: refuseReason })
      .then((res: any) => {
        getAndSetRequests();
        setRefuseReason("");
        setModerationAction("");
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при модерации");
      })
      .finally(() => setIsLoading(false));
  };

  const onUpdate = async (id: string, status: string) => {
    setIsLoading(true);
    reviewUpdate(id, { status: status, comment: refuseReason })
      .then((res: any) => {
        getAndSetUpdates();
        setRefuseReason("");
        setModerationAction("");
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при модерации");
      })
      .finally(() => setIsLoading(false));
  };

  const updateMiniApp = async (id: string, moderation: moderationTypes) => {
    setMiniAppId(id);
    setModerationAction(moderation);
  };

  const confirmApprove = async () => {
    switch (moderationAction) {
      case "approveRequest":
        onRequest(miniAppId, "approved");
        break;
      case "refuseRequest":
        onRequest(miniAppId, "refused");
        break;
      case "approveUpdate":
        onUpdate(miniAppId, "approved");
        break;
      case "refuseUpdate":
        onUpdate(miniAppId, "refused");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    confirmApprove();
  }, [refuseReason]);

  useEffect(() => {
    getAndSetRequests();
    getAndSetUpdates();
  }, []);

  const requestsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: false, wrap: false },
    { header_text: "Мини-приложение", narrow: false, wrap: false },
    { header_text: "Описание", narrow: false, wrap: false },
    { header_text: "Статус", narrow: false, wrap: false },
    { header_text: "Global", narrow: true, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const updatesTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: false, wrap: false },
    { header_text: "ID Мини-приложения", narrow: false, wrap: false },
    { header_text: "ID пользователя", narrow: false, wrap: false },
    { header_text: "Изменения", narrow: false, wrap: false },
    { header_text: "Статус", narrow: false, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const requestsList = (
    <>
      {requests.data.map((request, index) => (
        <tr key={index}>
          <td>{oneLine((requestsFirstEntry + index).toString())}</td>
          <td>{avatarWithTwoLines(request.logo, "app", request.names.ru, request.link)}</td>
          <td>{oneLine(request.descriptions.ru)}</td>
          <td>{mutedLine(request.mode)}</td>
          <td>{checkOrXIcon(request.global)}</td>
          <td>
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconCircleCheck className="icon" />}
                tooltip="Принять"
                action={() => updateMiniApp(request.id, "approveRequest")}
                color={"success"}
                modal={true}
                modal_id="#modal-danger"
              />
              <ActionButton
                icon={<IconCircleX className="icon" />}
                tooltip="Отклонить"
                action={() => updateMiniApp(request.id, "refuseRequest")}
                color={"danger"}
                modal={true}
                modal_id="#modal-refuse"
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const updatesList = (
    <>
      {updates.data.map((update, index) => (
        <tr key={index}>
          <td>{oneLine((updatesFirstEntry + index).toString())}</td>
          <td>{oneLine(update.mini_app_id)}</td>
          <td>{oneLine(update.user_id)}</td>
          <td>
            {avatarWithTwoLines(update.updates.logo_url, "app", "", "")}
            {mutedLine(`Название (RU): ${update.updates.names.ru}`)}
            {mutedLine(`Название (KZ): ${update.updates.names.kz}`)}
            {mutedLine(`Название (EN): ${update.updates.names.en}`)}
            {mutedLine(`Описание (RU): ${update.updates.descriptions.ru}`)}
            {mutedLine(`Описание (KZ): ${update.updates.descriptions.kz}`)}
            {mutedLine(`Описание (EN): ${update.updates.descriptions.en}`)}
            {mutedLine(`Ссылка: ${update.updates.link}`)}
            {mutedLine(`Бридж методы: ${update.updates.bridge_methods.join(", ")}`)}
          </td>
          <td>
            {oneLine(
              update.status,
              update.status === "approved"
                ? "success"
                : update.status === "refused"
                ? "danger"
                : update.status === "pending"
                ? "warning"
                : undefined
            )}
          </td>
          <td>
            {update.status === "pending" && (
              <div className="btn-list flex-nowrap">
                <ActionButton
                  icon={<IconCircleCheck className="icon" />}
                  tooltip="Принять"
                  action={() => updateMiniApp(update.id, "approveUpdate")}
                  color={"success"}
                  modal={true}
                  modal_id="#modal-danger"
                />
                <ActionButton
                  icon={<IconCircleX className="icon" />}
                  tooltip="Отклонить"
                  action={() => updateMiniApp(update.id, "refuseUpdate")}
                  color={"danger"}
                  modal={true}
                  modal_id="#modal-refuse"
                />
              </div>
            )}
          </td>
        </tr>
      ))}
    </>
  );

  const requestsTable = Table(
    requestsTableHeaders,
    requestsList,
    true,
    requests,
    updateRequestFirstEntry,
    getAndSetRequests
  );

  const moderationTable = Table(updatesTableHeaders, updatesList, true, updates, updateFirstEntry, getAndSetUpdates);

  const moderationPage = (
    <div className="d-flex flex-column gap-3">
      {requests.data.length ? (
        <div>
          <h3>{"Запросы на одобрении (development => production)"}</h3>
          {requestsTable}
        </div>
      ) : undefined}
      {updates.data.length ? (
        <div>
          <h3>Запросы на модерацию</h3>
          {moderationTable}
        </div>
      ) : undefined}
    </div>
  );

  const totalEntries = requests.data.length + updates.data.length;

  const moderationHeader = "Модерация мини-приложений";
  const moderationSubheaderText = "Количество запросов: ";
  const moderationSubheaderCount = totalEntries;

  return (
    <>
      <PageLayout
        children={moderationPage}
        isLoading={isLoading}
        filters={false}
        search={false}
        header={moderationHeader}
        subheaderText={moderationSubheaderText}
        subheaderCount={moderationSubheaderCount}
        dataLength={totalEntries}
        dateRange={false}
      />
      <DangerModal dangerDescription={dangerDescription} confirm={confirmApprove} />
      <RefuseReasonModal submitRefuse={setRefuseReason} />
    </>
  );
}
