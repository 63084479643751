import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getChatById, getChatMembers, getChatPosts, getChatFiles } from "api/chats";
import NavbarContainer from "components/navbar";
import { ChatFileType, ChatMemberType, ChatPostResponseType, ChatType, emptyChat } from "types/chat-type";
import { IconDownload } from "@tabler/icons-react";
import { getUsersByIds } from "api/users";
import { useSharedState } from "sharedStateProvider";
import { UserType } from "types/user-types";
import TableSkeleton from "components/tables/table-skeleton";
import ActionButton from "components/tables/action-btn";

export default function ChatPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { notify } = useSharedState();

  const [chat, setChat] = useState<ChatType>(emptyChat);
  const [chatMembers, setChatMembers] = useState<ChatMemberType[]>([]);
  const [chatPosts, setChatPosts] = useState<ChatPostResponseType>({
    order: [],
    posts: {},
    next_post_id: "",
    prev_post_id: "",
    has_next: false,
  });
  const [chatFiles, setChatFiles] = useState<ChatFileType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 байт";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["байт", "Кб", "Мб", "Гб", "Тб", "Пб", "Эб", "Зб", "Йб"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const getAndSetChat = async () => {
    await getChatById(id)
      .then((res: ChatType) => {
        setChat(res);
      })
      .catch((error: any) => {
        navigate("/chats", { replace: true });
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении чата");
      });
  };

  const getAndSetChatMembers = async () => {
    await getChatMembers(id)
      .then((members: ChatMemberType[]) => getMembersInfo(members))
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении участников чата");
      });
  };

  const getMembersInfo = async (members: ChatMemberType[]) => {
    let userIds = members.map((mem) => mem.user_id);
    await getUsersByIds(userIds)
      .then((usersInfo: UserType[]) => {
        members.forEach((mem) => {
          let info = usersInfo.find((user) => user.id === mem.user_id);
          mem.user_info = info;
        });
        setChatMembers(members);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении информации об участниках");
      });
  };

  const getAndSetChatPosts = async () => {
    await getChatPosts(id)
      .then((res: ChatPostResponseType) => {
        setChatPosts(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении сообщений чата");
      });
  };

  const getAndSetChatFiles = async () => {
    await getChatFiles(id)
      .then((res: ChatFileType[]) => {
        setChatFiles(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении файлов чата");
      });
  };

  const onDownload = async (fileUrl: string) => {
    let link = document.createElement("a");
    link.setAttribute("download", "");
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const initChatPage = async () => {
    setIsLoading(true);
    await Promise.all([
      getAndSetChat(),
      getAndSetChatMembers(),
      // getAndSetChatPosts(),
      getAndSetChatFiles(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    id && initChatPage();
  }, [id]);

  return (
    <div className="page">
      <NavbarContainer />
      <div className="page-wrapper">
        <div className="page-body">
          {isLoading ? (
            <div className="container-xl">
              <TableSkeleton />
            </div>
          ) : (
            <>
              {chat.id && (
                <div className="container-xl mb-3">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Информация о чате</h3>
                    </div>
                    <div className="card-body">
                      <div className="datagrid">
                        <div className="datagrid-item">
                          <div className="datagrid-title">Название чата</div>
                          <div className="datagrid-content">{chat.display_name}</div>
                        </div>
                        <div className="datagrid-item">
                          <div className="datagrid-title">Описание</div>
                          <div className="datagrid-content">{chat.description}</div>
                        </div>
                        <div className="datagrid-item">
                          <div className="datagrid-title">Тип чата</div>
                          <div className="datagrid-content">{chat.type}</div>
                        </div>
                        <div className="datagrid-item">
                          <div className="datagrid-title">ID чата</div>
                          <div className="datagrid-content">{chat.id}</div>
                        </div>
                        <div className="datagrid-item">
                          <div className="datagrid-title">ID создателя</div>
                          <div className="datagrid-content">{chat.creator_id}</div>
                        </div>
                        <div className="datagrid-item">
                          <div className="datagrid-title">Создан</div>
                          <div className="datagrid-content">
                            {`${new Date(chat.create_at * 1000).toLocaleString()}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="container-xl mb-3">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Пользователи</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                      <thead>
                        <tr>
                          <th className="w-1">№</th>
                          <th>Пользователь</th>
                          <th>Имя</th>
                          <th>Подразделение</th>
                        </tr>
                      </thead>
                      <tbody>
                        {chatMembers.length > 0 ? (
                          chatMembers.map((member, index) => (
                            <tr key={index}>
                              <td>
                                <div>{index + 1}</div>
                              </td>
                              <td>
                                <div className="d-flex py-1 align-items-center">
                                  <div className="flex-fill">
                                    <div className="font-weight-medium">{member.user_id}</div>
                                    <div className="text-muted">
                                      <div className="text-reset">{member.roles}</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {member.user_info?.first_name || "-"} {member.user_info?.last_name || "-"}
                              </td>
                              <td>{member.user_info?.org_unit || "-"}</td>
                            </tr>
                          ))
                        ) : (
                          <div className="p-3 text-nowrap">Нет данных о пользователях</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="container-xl mb-3">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Сообщения</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                      <thead>
                        <tr>
                          <th className="w-1">№</th>
                          <th>ID сообщения</th>
                          <th>Создано</th>
                          <th>Текст</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(chatPosts.posts).length > 0 ? (
                          Object.values(chatPosts.posts).map((post, index) => (
                            <tr key={index}>
                              <td>
                                <div>{index + 1}</div>
                              </td>
                              <td>{post.id}</td>
                              <td>
                                {`${new Date(
                                  post.create_at * 1000
                                ).toLocaleString()}`}
                              </td>
                              <td>{post.message}</td>
                            </tr>
                          ))
                        ) : (
                          <div className="p-3 text-nowrap">
                            Нет данных о сообщениях
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
              <div className="container-xl">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Файлы</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                      <thead>
                        <tr>
                          <th>№</th>
                          <th>Название</th>
                          <th>Тип файла</th>
                          <th>Размер</th>
                          <th>ID пользователя</th>
                          <th>ID канала</th>
                          <th className="w-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {chatFiles.length > 0 ? (
                          chatFiles.map((file, index) => (
                            <tr key={index}>
                              <td data-label="№">
                                <div>{index + 1}</div>
                              </td>
                              <td data-label="Название">
                                <div>{file.filename}</div>
                              </td>
                              <td data-label="Тип файла">
                                <div>{file.type}</div>
                              </td>
                              <td data-label="Размер" className="text-nowrap">
                                <div>{formatBytes(file.size)}</div>
                              </td>
                              <td data-label="ID пользователя">{file.user_id}</td>
                              <td data-label="ID канала">{file.channel_id}</td>
                              <td>
                                <div className="btn-list flex-nowrap">
                                  <ActionButton
                                    icon={<IconDownload className="icon" />}
                                    text="Скачать"
                                    action={() => onDownload(file.url)}
                                    color={"info"}
                                    modal={false}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="p-3 text-nowrap">Нет данных о файлах</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
